body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", "Roboto Condensed", sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}

* {
  font-family: "Roboto", "Roboto Condensed", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: 0.1s;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration: none;
}

button {
  border: none;
  outline: none;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track-piece {
  background-color: transparent;
}

::-webkit-scrollbar-thumb:vertical {
  border-radius: 4px;
  height: 6px;
  background: #E7B556;
}

::-webkit-scrollbar-thumb:horizontal {
  border-radius: 4px;
  height: 6px;
}

.alert-enter {
  transform: translate(100%);
}


.alert-enter-active {
  transform: translate(0);
  transition: transform 0.2s linear;
}

.alert-exit {
  transform: translate(0);
}

.alert-exit-active {
  transform: translate(100%);
  transition:  transform 0.2s linear;
}


/*drawer start*/
.drawer-enter {
  transform: translate(-100%);
}


.drawer-enter-active {
  transform: translate(0);
  transition: transform 0.2s linear;
}

.drawer-exit {
  transform: translate(0);
}

.drawer-exit-active {
  transform: translate(-100%);
  transition:  transform 0.2s linear;
}

/*drawer end*/

/*drawer right start*/
.drawer-right-enter {
  transform: translate(100%);
}


.drawer-right-enter-active {
  transform: translate(0);
  transition: transform 0.2s linear;
}

.drawer-right-exit {
  transform: translate(0);
}

.drawer-right-exit-active {
  transform: translate(100%);
  transition:  transform 0.2s linear;
}

/*drawer right end*/
/*modal right start*/

.modal_offset-enter {
  opacity: 0;
}
.modal_offset-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.modal_offset-exit {
  opacity: 1;
}
.modal_offset-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

/*modal right end*/
